import React from "react";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { COLORS } from "../../../COLORS";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IoGitCommitSharp } from "react-icons/io5";
import { IoLocationOutline } from "react-icons/io5";
import { MdImageNotSupported } from "react-icons/md";
type TypeAnnonce = {
  id: string;
  categorie: string;
  description: string;
  image?: [string];
  numero: string;
  price: string;
  pseudo: string;
  titre: string;
  type: string;
  ville: string;
  setOneAnnonce: React.Dispatch<React.SetStateAction<string>>;
};
const Annonce = ({
  id,
  categorie,
  description,
  image,
  numero,
  price,
  pseudo,
  titre,
  type,
  ville,
  setOneAnnonce,
}: TypeAnnonce) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <StyledAnnonce
      onClick={() => setOneAnnonce(id)}
      className="animate__animated animate__bounceIn"
    >
      <div className="f-box">
        <div className="box-img">
          {image && image.length > 0 ? (
            <img src={image && image[0]} />
          ) : (
            <MdImageNotSupported className="noImg" />
          )}
          {/* <Slider {...settings}>
              {annonce.photos.map((photo, index) => (
                <img key={index} src={photo} />
              ))}
            </Slider> */}
        </div>
        <div className="details">
          <span>
            <IoIosInformationCircleOutline />
            {type}
          </span>
          <span>
            <IoGitCommitSharp />
            {categorie}
          </span>
          <span>
            <IoLocationOutline />
            {ville}
          </span>
        </div>
        <h2>{titre}</h2>
        <div className="box-price">
          <span>{price} €</span>
        </div>
      </div>
    </StyledAnnonce>
  );
};

export default Annonce;

const StyledAnnonce = styled.div`
  background: ${COLORS.purple};
  padding: 10px;
  width: 20%;
  margin: 10px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
  .f-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h2 {
      text-align: center;
      margin-top: 10px;
    }
    width: 100%;
    /* background: green; */
    .box-img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      /* background: orange; */
      .noImg {
        font-size: 15em;
        /* margin: 0px auto; */
        color: ${COLORS.gold};
      }
      img {
        width: 100%;
        margin-bottom: 5px;
        border-radius: 10px;
      }
    }
    .details {
      width: 100%;
      border-radius: 5px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      background: ${COLORS.white};
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
      padding: 2px;
      span {
        display: flex;
        align-items: center;
      }
    }
    .box-price {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      span {
        padding: 5px;
        font-size: 1.1em;
        border-radius: 5px;
        background: ${COLORS.blueLow};
      }
    }
  }
  @media screen and (max-width: 429px) {
    width: 43%;
    .f-box {
      h2 {
        font-size: 1em;
      }
      .box-img {
        .noImg {
          font-size: 8em;
        }
      }
      .details {
        padding: 5px;
        span {
          font-size: 0.7em;
        }
      }
    }
  }
`;
