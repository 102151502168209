import React from "react";
import styled from "styled-components";
import { COLORS } from "../../COLORS";

const BtnLoader = () => {
  return (
    <StyledBtnLoader>
      <span className="loader"></span>
    </StyledBtnLoader>
  );
};

export default BtnLoader;

const StyledBtnLoader = styled.button`
  background: ${COLORS.green};
  padding: 10px;
  color: ${COLORS.white};
  border: none;
  border-radius: 10px;
  width: auto;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
  .loader {
    width: 35px;
    height: 35px;
    border: 5px solid #fff;
    /* background: ${COLORS.green}; */
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
