import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../COLORS";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IoGitCommitSharp, IoLocationOutline } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import Slider from "react-slick";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaPhoneFlip } from "react-icons/fa6";
import { MdImageNotSupported } from "react-icons/md";
import { MdOutlineNavigateNext } from "react-icons/md";
import { MdOutlineNavigateBefore } from "react-icons/md";
type TypeProps = {
  oneAnnonce: string;
  setOneAnnonce: React.Dispatch<React.SetStateAction<string>>;
};

type TypeState = {
  categorie: string;
  createdAt: string;
  description: string;
  images: [string];
  numero: string;
  price: string;
  titre: string;
  type: string;
  ville: string;
  pseudo: string;
  point: string;
};

const PopOneAnnonce = ({ oneAnnonce, setOneAnnonce }: TypeProps) => {
  const [annonce, setAnnonce] = useState<TypeState[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const vertDate = (value: string) => {
    const date = new Date(value);
    const now = new Date();

    // On définit aujourd'hui et hier en retirant le temps (heure, minute, seconde)
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Vérification de la date
    if (date >= today) {
      return "Publiée aujourd'hui";
    } else if (date >= yesterday && date < today) {
      return "Publiée hier";
    } else {
      // Si la date n'est ni aujourd'hui ni hier, on affiche le jour, mois et année
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "long" }); // Mois en toutes lettres
      const year = date.getFullYear();
      return `Publiée le ${day} ${month} ${year}`;
    }
  };

  const whatsappMessage = (
    num: string,
    name: string,
    titre: string,
    type: string
  ): void => {
    const url = `https://wa.me/${num}?text=Bonjour ${name}, je suis intéressé(e) par votre ${titre} en ${type} sur le site d'annonce www.emohup.fr`;

    window.open(url, "_blank");
  };

  const nextSlide = (valu: number) => {
    if (currentIndex !== valu - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  };

  // Fonction pour passer à l'image précédente
  const prevSlide = (valu: number) => {
    if (currentIndex === 0) {
      setCurrentIndex(valu - 1); // Va à la dernière image
    } else {
      setCurrentIndex((prevIndex) => prevIndex - 1); // Sinon, décrémente l'index
    }
  };

  useEffect(() => {
    const getAnnonce = async () => {
      try {
        const res = await axios({
          method: "get",
          url: `${process.env.REACT_APP_API}annonces/one/${oneAnnonce}`,
          withCredentials: true,
        });
        if (res.data) {
          setAnnonce([res.data]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getAnnonce();
  }, []);
  return (
    <StyledPopOneAnnonce onClick={() => setOneAnnonce("")}>
      <div className="box-all" onClick={(e) => e.stopPropagation()}>
        {annonce ? (
          annonce.map((ann, index) => (
            <div
              className="annonce-selected"
              key={index}
              onClick={(e) => e.stopPropagation()}
            >
              <h1>{ann.titre}</h1>
              <div className="slider-to-img">
                {ann.images && ann.images.length > 0 ? (
                  // <Slider {...settings}>
                  //   {ann.images.map((photo, index) => (
                  //     <img key={index} src={photo} />
                  //   ))}
                  // </Slider>

                  <>
                    {ann.images.length > 1 && (
                      <MdOutlineNavigateBefore
                        className="icon-change"
                        onClick={() => prevSlide(ann.images.length)}
                      />
                    )}
                    <img
                      src={ann.images[currentIndex]}
                      alt="img-annocne"
                      className="animate__animated animate__zoomIn"
                    />
                    {ann.images.length > 1 && (
                      <MdOutlineNavigateNext
                        className="icon-change"
                        onClick={() => nextSlide(ann.images.length)}
                      />
                    )}
                  </>
                ) : (
                  <MdImageNotSupported className="noImg" />
                )}
              </div>
              <div className="details">
                <span>
                  <IoIosInformationCircleOutline />
                  {ann.type}
                </span>
                <span>
                  <IoGitCommitSharp />
                  {ann.categorie}
                </span>
                <span>
                  <IoLocationOutline />
                  {ann.ville}
                </span>
              </div>
              <p className="description">{ann.description}</p>
              <div className="contact">
                <IoLogoWhatsapp
                  className="i whatsap"
                  onClick={() =>
                    whatsappMessage(ann.numero, ann.pseudo, ann.titre, ann.type)
                  }
                />
                <FaPhoneFlip
                  className="i phone"
                  onClick={() => (window.location.href = `tel:+${ann.numero}`)}
                />
              </div>
              <div className="sp-profil">
                <span> {vertDate(ann.createdAt)}</span>
                <strong>
                  <CgProfile className="i-profil" />
                  {ann.pseudo}
                </strong>
              </div>
              <div className="float-price-point">
                <span className="price">{ann.price} €</span>
                <span className="info-point">
                  {ann.point === "true" ? "+1 pt" : "0 pt"}
                </span>
              </div>
            </div>
          ))
        ) : (
          <strong className="introuvable">Annonce introuvable</strong>
        )}
      </div>
    </StyledPopOneAnnonce>
  );
};

export default PopOneAnnonce;

const StyledPopOneAnnonce = styled.div`
  position: absolute;
  z-index: 45;
  background: #2929287c;
  /* background: red; */
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  .introuvable {
    background: ${COLORS.white};
  }
  .box-all {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${COLORS.blueLow};
    padding: 5px;
    display: block;
    position: relative;
    width: 40%;
    height: 100%;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .annonce-selected {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      /* background: blue; */
      height: 100%;
      h1 {
        text-align: center;
        font-size: 1.3em;
      }
      .slider-to-img {
        width: 100%;
        height: 100%;
        /* margin: 0px 0px 25px 0px; */
        padding: 5px;
        /* background: pink; */
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-change {
          font-size: 2em;
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.5);
          color: white;
          padding: 5px;
          margin: 0px 10px;
          border: none;
          cursor: pointer;
          border-radius: 50%;
          transition: background-color 0.3s ease;
        }
        .icon-change:hover {
          background-color: rgba(0, 0, 0, 0.8);
        }
        .noImg {
          font-size: 10em;
          display: flex;
          margin: 0px auto;
          align-self: center;
          color: ${COLORS.gold};
          border-radius: 10px;
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
        }
        img {
          width: 70%;
          border-radius: 5px;
          transition: transform 0.5s ease-in-out;
        }
      }
      .details {
        width: 100%;
        border-radius: 5px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        background: pink;
        span {
          display: flex;
          align-items: center;
        }
      }
      .description {
        margin: 5px 0px;
      }
      .sp-profil {
        margin-top: 10px;
        display: flex;
        align-items: center;
        span {
          font-size: 0.7em;
        }
        strong {
          display: flex;
          align-items: center;
          .i-profil {
            margin-left: 10px;
          }
        }
      }
      .float-price-point {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 0px;
        right: 0px;
        background: ${COLORS.orange};
        padding: 5px;
        border-radius: 0px 10px 0px 10px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
        .price {
          font-size: 1.5em;
        }
        .info-point {
          font-size: 1em !important;
          background: ${COLORS.gold};
          margin-top: 5px;
          border-radius: 10px;
          padding: 5px;
        }
      }
      .contact {
        margin: 0px auto;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border-bottom: solid 3px ${COLORS.purple};
        /* border-radius: 10px; */
        /* animation: waveEffect 4s infinite ease-in-out; */
        /* box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); */
        .i.whatsap {
          font-size: 4.3em;
          /* color: #25d366; */
          color: ${COLORS.green};
          padding: 5px;
          background: ${COLORS.purple};
          border-radius: 37% 63% 20% 80% / 78% 49% 51% 22%;
          animation: waveBorder 4s infinite ease-in-out;
          box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
        }
        .i {
          cursor: pointer;
          font-size: 4em;
          /* color: #2196f3; */
          color: ${COLORS.green};
          padding: 5px;
          background: ${COLORS.purple};
          border-radius: 37% 63% 20% 80% / 78% 49% 51% 22%;
          animation: waveBorder 4s infinite ease-in-out;
          box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
        }
      }
      @keyframes waveBorder {
        0% {
          border-radius: 37% 63% 20% 80% / 78% 49% 51% 22%;
        }
        25% {
          border-radius: 50% 50% 30% 70% / 60% 70% 30% 40%;
          box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4);
        }
        50% {
          border-radius: 40% 60% 40% 60% / 50% 50% 60% 40%;
        }
        75% {
          border-radius: 30% 70% 50% 50% / 70% 30% 40% 60%;
        }
        100% {
          border-radius: 37% 63% 20% 80% / 78% 49% 51% 22%;
          box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
        }
      }
      @keyframes waveEffect {
        0% {
          border-radius: 10px 20px 10px 20px; /* Forme de départ */
        }
        25% {
          border-radius: 20px 10px 20px 10px; /* Transition douce */
        }
        50% {
          border-radius: 30px 15px 30px 15px; /* Forme plus arrondie */
        }
        75% {
          border-radius: 15px 30px 15px 30px; /* Retour progressif */
        }
        100% {
          border-radius: 10px 20px 10px 20px; /* Retour à la forme initiale */
        }
      }
    }
  }

  @media screen and (max-width: 429px) {
    position: fixed;
    padding: 0px;
    height: 100vh;
    .box-all {
      width: 100%;
      height: auto !important;
      padding: 5px;
      margin: 3px;
      .annonce-selected {
        h1 {
          font-size: 1.5em;
        }
        .slider-to-img {
          margin: 0px;
          padding: 0px;
          .icon-change {
            font-size: 1em;
            padding: 0px;
          }
          img {
            width: 70%;
          }
        }
        .details {
          margin-top: 5px;
          span {
            font-size: 0.8em;
          }
        }
        .description {
          font-size: 0.8em;
        }
        .contact {
          width: 100%;
          padding: 0px 60px;
          .i.whatsap {
            font-size: 3.3em;
          }
          .i {
            font-size: 3em;
          }
        }
        .sp-profil {
          width: 100%;
          justify-content: space-around;
        }
        .float-price-point {
          .price {
            font-size: 1.1em;
          }
        }
      }
    }
  }
`;
