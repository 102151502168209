import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../COLORS";

const Premium = () => {
  return (
    <StyledPremium>
      <span className="inff">1200 pts - Premium*</span>
      <div className="recomp">
        <span>???</span>
      </div>
      <div className="recomp">
        <span>???</span>
      </div>
      <span className="inff-f">
        * Récompenses non permanente (peut changer ou supprimer)
      </span>
    </StyledPremium>
  );
};

export default Premium;
const StyledPremium = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: ${COLORS.purple};
  border-radius: 15px;
  width: 50%;
  margin-bottom: 20px;
  padding: 20px 20px 5px 20px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
  .inff {
    background: ${COLORS.white};
    text-align: center;
    padding: 5px;
  }
  .inff,
  .inff-f {
    width: 100%;
  }
  .inff-f {
    margin-top: 20px;
    font-size: 0.7em;
  }
  .recomp {
    width: 150px;
    height: 150px;
    /* background-color: #ff5722; Couleur principale du pentagone */
    background: ${COLORS.gold};
    clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-family: "Bangers", sans-serif;
    font-size: 18px;
    text-align: center;
    margin: 10px;
    //border: solid px black;
    //box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Ombre principale */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
    transform: rotateX(10deg); /* Inclinaison pour effet 3D */
    cursor: pointer;
    transition: 0.3s ease;
    span {
      padding: 10px;
      font-family: "Bangers", sans-serif;
      text-align: center;
      font-size: 1.5em;
    }
  }
  .recomp:hover {
    transform: scale(1.1);
  }
  .recomp::before {
    content: "";
    position: absolute;
    top: 10px; /* Décalage pour simuler la profondeur */
    left: 5px;
    width: 150px;
    height: 150px;
    background-color: #d84315; /* Couleur plus foncée pour l'ombre */
    clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
    z-index: -1; /* Mettre l'ombre derrière le pentagone principal */
    transform: rotateX(10deg); /* Même inclinaison pour conserver l'effet */
  }
  @media screen and (max-width: 429px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;
