import React from "react";
import styled from "styled-components";
import Recompense from "../Footer/Recompense/Recompense";
import Cgu from "../Footer/CGU/Cgu";
import Mentions from "../Footer/Mentions/Mentions";
import Politique from "../Footer/Politique/Politique";
import { COLORS } from "../../COLORS";

type propType = {
  setCompo: React.Dispatch<React.SetStateAction<string>>;
  compo: string;
};

const Popup = ({ setCompo, compo }: propType) => {
  const whileText = () => {
    switch (compo) {
      case "recompense":
        return <Recompense setCompo={setCompo} />;
      case "cgu":
        return <Cgu />;
      case "mentionlegales":
        return <Mentions />;
      case "politique":
        return <Politique />;
      default:
        setCompo("");
        return;
    }
  };
  return (
    <StyledPopup onClick={() => setCompo("")}>
      <div className="box" onClick={(e) => e.stopPropagation()}>
        {whileText()}
      </div>
    </StyledPopup>
  );
};

export default Popup;
const StyledPopup = styled.div`
  position: absolute;
  z-index: 15;
  background: #2929289c;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  height: 100%;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  .box {
    height: 90%;
    width: 90%;
    padding: 10px;
    background: ${COLORS.white};
    border-radius: 10px;
    display: flex;
    /* justify-content: center;
    align-items: center; */
  }
  //width =< 429px
  @media screen and (max-width: 429px) {
    .box {
      width: 100%;
    }
  }
`;
