import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../COLORS";
import { FaSquareWebAwesome } from "react-icons/fa6";
import { SlBadge } from "react-icons/sl";
import RecentsAnnonces from "./RecentsAnnonces/RecentsAnnonces";
import RecentsEvenements from "./RecentsEvenements/RecentsEvenements";
import Categories from "./Categories/Categories";
import RecompBasic from "../Points/Basic/RecompBasic";

const Home = ({
  setCompo,
}: {
  setCompo: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <StyledHome>
      <div className="banniere-home">
        <h1>Le site d'annonces qui vous récompense</h1>
        <p>
          {" "}
          <SlBadge className="icon-i" /> À chaque publication validée gagnez 1
          point{" "}
        </p>
        <p>
          {" "}
          <FaSquareWebAwesome className="icon-i" />
          Soyez le premier à être récompensé dès 200 points
        </p>
        <strong className="plus-infos" onClick={() => setCompo("recompense")}>
          En savoir plus
        </strong>
      </div>
      <div className="to-nav-to-post">
        <h2>Découvrez les annonces</h2>
        <Link to="/annonces" className="the-a a_anime">
          Voir
        </Link>
      </div>
      <div className="banniere-recompense">
        <div className="text-recompense">
          <strong>Récompenses</strong>
        </div>
        <RecompBasic />
        <Link to="/points" className="the-a">
          Voir plus
        </Link>
      </div>
      <div className="to-nav-to-post">
        <h2>Commencez à gagner des points</h2>
        <Link to="/post" className="the-a a_anime">
          Publier une annonce
        </Link>
      </div>
      <Categories />
      {/* <RecentsAnnonces />
      <RecentsEvenements /> */}
    </StyledHome>
  );
};

export default Home;
const StyledHome = styled.section`
  /* background: ${COLORS.blueLow}; */
  border-radius: 10px;
  padding: 10px;
  margin: 20px auto;
  width: 80%;
  .banniere-home {
    background: ${COLORS.blueLow};
    background-image: url("./items/bannierde.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 25px;
    padding: 10px;
    margin: 25px 0px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
    h1 {
      text-align: center;
      font-size: 3.7em;
      margin-bottom: 10px;
      letter-spacing: 0.4em;
      text-transform: uppercase;
      color: ${COLORS.gold};
    }
    p {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.6em;
      color: ${COLORS.white};
      margin-top: 10px;
      .icon-i {
        margin-right: 5px;
        font-size: 2em;
        color: ${COLORS.gold};
      }
    }
    .plus-infos {
      display: block;
      text-align: center;
      cursor: pointer;
      text-decoration: none;
      color: ${COLORS.white};
      background: ${COLORS.green};
      width: 10%;
      margin: 10px auto;
      border-radius: 5px;
      padding: 5px;
      /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4); */
      transition: background-color 0.3s ease, color 0.3s ease;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4), 0px 0px 15px ${COLORS.green};
    }
    .plus-infos:hover {
      background: ${COLORS.greenDark};
    }
  }
  .to-nav-to-post,
  .banniere-recompense {
    width: 70%;
    background: ${COLORS.purple};
    /* background-image: url("./items/lilbannier.jpg"); */
    background-position: center;
    margin: 30px auto;
    padding: 10px;
    display: flex;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
    .text-recompense {
      position: relative;
      overflow: hidden;
      width: 100%; /* Ajustez la largeur selon votre besoin */
      height: 50px;
      strong {
        font-size: 1.7em;
        margin-bottom: 10px;
        display: inline-block;
        white-space: nowrap;
        animation: scroll 20s linear infinite;
        padding-right: 100%;
      }
    }
    h2 {
      color: ${COLORS.black};
    }
    .the-a {
      margin-top: 15px;
      padding: 10px;
      text-decoration: none;
      font-size: 1.4em;
      /* background: ${COLORS.purple}; */
      background: ${COLORS.green};
      border-radius: 5px;
      /* color: ${COLORS.white}; */
      color: ${COLORS.white};
      //box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
      transition: background-color 0.3s ease, color 0.3s ease;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4), 0px 0px 15px ${COLORS.green};
    }
    .a_anime {
      display: inline-block;
      animation: bounce 1s infinite;
    }
    .the-a:hover {
      background: ${COLORS.greenDark};
    }
    .the-a:visited {
      text-decoration: none;
      color: none;
    }
  }
  .banniere-recompense {
    width: 100% !important;
    background: ${COLORS.blueLow} !important;
  }

  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-30%);
    }
  }
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0); /* Position de base */
    }
    40% {
      transform: translateY(-5px); /* Montée */
    }
    60% {
      transform: translateY(-5px); /* Petit rebond */
    }
  }

  .bouncing-text {
    display: inline-block;
    animation: bounce 1s infinite; /* Animation répétée en boucle */
  }

  //width =< 429px
  @media screen and (max-width: 429px) {
    margin-top: 50px;
    width: 100%;
    .banniere-home {
      h1 {
        font-size: 1.5em;
      }
      p {
        width: 100%;
        font-size: 1.1em;
        margin-bottom: 10px;
        .icon-i {
          margin-right: 10px;
        }
      }
      .plus-infos {
        width: 30%;
      }
    }
    .banniere-recompense {
      .text-recompense {
        strong {
          animation: scroll 10s linear infinite;
        }
      }
    }
    .to-nav-to-post {
      width: 80%;
      h2 {
        text-align: center;
      }
    }
  }
`;
