import React from "react";
import styled from "styled-components";
import FormImages from "../ImagesUpload/FormImages";
type PropsType = {
  titre: string;
  setTitre: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  price: string;
  setPrice: React.Dispatch<React.SetStateAction<string>>;
};
const EtapeTwo = ({
  titre,
  setTitre,
  description,
  setDescription,
  price,
  setPrice,
}: PropsType) => {
  return (
    <StyledEtapeTwo>
      <input
        type="text"
        placeholder="Titre de l'annonce*"
        className="input-titre"
        defaultValue={titre ? titre : ""}
        onChange={(e) => setTitre(e.target.value)}
      />
      <textarea
        placeholder="Description*..."
        defaultValue={description ? description : ""}
        onChange={(e) => setDescription(e.target.value)}
      ></textarea>
      <input
        type="number"
        placeholder="00.00*"
        className="input-titre"
        defaultValue={price ? price : ""}
        onChange={(e) => setPrice(e.target.value)}
      />
      <FormImages />
    </StyledEtapeTwo>
  );
};

export default EtapeTwo;
const StyledEtapeTwo = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  .input-titre {
    width: 50%;
    font-size: 1.3em;
    padding: 5px;
    outline: none;
    border: none;
    margin-bottom: 10px;
    border-radius: 3px;
  }
  textarea {
    width: 80%;
    font-size: 1.3em;
    padding: 5px;
    outline: none;
    border: none;
    margin-bottom: 10px;
    border-radius: 3px;
    resize: none;
    height: 100px;
  }

  //width =< 429px
  @media screen and (max-width: 429px) {
    width: 80%;
    .input-titre {
      width: 80%;
      font-size: 1em;
    }
    textarea {
      width: 100%;
      font-size: 1em;
    }
  }
`;
