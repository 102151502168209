import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { COLORS } from "../../COLORS";
import BtnLoader from "../Utils/BtnLoader";

const Contact = () => {
  const [mail, setMail] = useState("");
  const [hiden, setHiden] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const contactMail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!mail || !message) {
      return toast.error("Email et message sont obligatoires");
    }
    if (message.length > 180) {
      return toast.error("Message trop long, max 180 caractères");
    }
    setLoader(true);
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}contact`,
        withCredentials: true,
        data: { mail, hidden: hiden, message },
      });
      if (res.data.message) {
        setLoader(false);
        setMail("");
        setMessage("");
        return toast.success(res.data.message);
      }
    } catch (error) {
      console.log(error);
      return toast.error("Une erreur est survenue");
    }
  };
  return (
    <StyledContact>
      <h1>Contactez-nous !</h1>
      <form onSubmit={(e) => contactMail(e)}>
        <input
          type="email"
          onChange={(e) => setMail(e.target.value)}
          placeholder="Votre email*"
          value={mail ? mail : ""}
        />
        <input type="hidden" onChange={(e) => setHiden(e.target.value)} />
        <div>
          <span className={message.length > 180 ? "color" : ""}>
            {message.length} caractère{message.length > 1 ? "s" : ""}
          </span>
          <textarea
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Votre message*"
            value={message ? message : ""}
          ></textarea>
        </div>
        {loader ? (
          <div className="btn-loader-compo">
            <BtnLoader />
          </div>
        ) : (
          <input type="submit" value="Envoyer" />
        )}
      </form>
    </StyledContact>
  );
};

export default Contact;
const StyledContact = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  form {
    width: 40%;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 10px;
    border: solid 2px ${COLORS.green};
    input {
      margin-top: 20px;
      border: none;
      outline: none;
      border-radius: 5px;
      font-size: 1em;
      padding: 5px;
    }
    div {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      span {
        font-size: 0.8em;
        font-family: "Courier New", Courier, monospace;
      }
      .color {
        color: ${COLORS.danger};
        font-size: 1em;
      }
      textarea {
        border: none;
        outline: none;
        border-radius: 5px;
        font-size: 1em;
        padding: 5px;
        height: 10vh;
        resize: none;
      }
    }
    input:last-child {
      width: 20%;
      font-size: 1.3em;
      background: ${COLORS.green};
      padding: 10px;
      color: ${COLORS.white};
      cursor: pointer;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
      margin-top: 10px;
      padding: 5px !important;
      border: none;
      outline: none;
      border-radius: 5px;
    }

    .btn-loader-compo {
      margin: 10px 0px;
      width: 20%;
    }
  }
  @media screen and (max-width: 429px) {
    margin-top: 40px;
    padding-top: 50px;
    form {
      width: 90%;
    }
    input:last-child {
      width: 40% !important;
    }
  }
`;
