import React from "react";
import styled from "styled-components";

const FormEvenement = () => {
  return (
    <StyledFormEvenement>
      En cours développement - Ici publiez vos événements (soirée, sortie
      ect...)
    </StyledFormEvenement>
  );
};

export default FormEvenement;
const StyledFormEvenement = styled.div`
  padding: 5px;
`;
