import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../../COLORS";

const Categories = () => {
  const [categorie, setCategorie] = useState([
    "Auto-Moto",
    "Immobilier",
    "Maison",
    "Loisirs",
    "Multimédia",
    "Services",
    "Divers",
  ]);
  return (
    <StyledCategories>
      <h3>Catégories : </h3>
      <div className="list-cat">
        {categorie.map((cat, index) => (
          <li key={index}>
            {" "}
            <Link to="">{cat}</Link>{" "}
          </li>
        ))}
      </div>
    </StyledCategories>
  );
};

export default Categories;
const StyledCategories = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 15px auto;
  /* background: red; */
  /* justify-content: center; */
  /* align-items: center; */
  h3 {
    font-size: 1.5em;
  }
  .list-cat {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .list-cat > li {
    padding: 5px;
    margin: 15px;
    border-radius: 5px;
    background: ${COLORS.purple};
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
    transition: background-color 0.3s ease, color 0.3s ease;
    a {
      text-decoration: none;
      //font-weight: 700;
      font-size: 1.3em;
    }
  }
  .list-cat > li:hover {
    background: ${COLORS.orange};
  }
`;
