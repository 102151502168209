import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../COLORS";

const Politique = () => {
  return (
    <StyledPolitique>
      <h1>Politique de Confidentialité - Emohup.fr</h1>
      <section className="first-box">
        <h2>1. Responsable du traitement des données</h2>
        <p>
          Le site <strong>www.emohup.fr</strong> est édité par l'équipe de
          développement EmohUp, qui est responsable de la collecte et du
          traitement des données personnelles conformément au Règlement Général
          sur la Protection des Données (RGPD) et à la loi Informatique et
          Libertés.
        </p>
        <p>
          <strong>Coordonnées :</strong> dev.frenchlod@gmail.com
        </p>
      </section>

      <section className="first-box">
        <h2>2. Données collectées</h2>
        <p>
          Nous collectons les informations suivantes lorsque vous utilisez notre
          site :
        </p>
        <ul>
          <li>
            <strong>Données fournies volontairement</strong> : lors de la
            publication d'une annonce, des informations telles que le pseudo, le
            numéro de téléphone, la catégorie, le type, la ville, le titre et la
            description de l’annonce.
          </li>
          <li>
            <strong>Données de navigation</strong> : l'adresse IP et les
            informations relatives à votre utilisation du site (comme les pages
            consultées) sont collectées à des fins d’analyse via des cookies.
          </li>
        </ul>
        <p>
          Nous ne collectons pas de données sensibles telles que la
          géolocalisation ou des informations financières.
        </p>
      </section>

      <section className="first-box">
        <h2>3. Finalités de la collecte des données</h2>
        <p>
          Les données personnelles collectées sont utilisées pour les finalités
          suivantes :
        </p>
        <ul>
          <li>Gestion et modération des annonces postées sur le site.</li>
          <li>Amélioration de l’expérience utilisateur sur le site.</li>
          <li>Analyse des statistiques de visite via Google Analytics.</li>
          <li>Prévention des fraudes et des activités suspectes.</li>
        </ul>
      </section>

      <section className="first-box">
        <h2>4. Partage des données</h2>
        <p>
          Nous ne partageons pas vos données personnelles avec des tiers, sauf
          dans les cas suivants :
        </p>
        <ul>
          <li>
            Avec nos prestataires de service, tels que OVH pour l’hébergement du
            site et Google Analytics pour l’analyse statistique.
          </li>
          <li>
            En cas de demande légale, par exemple dans le cadre d'une procédure
            judiciaire.
          </li>
        </ul>
      </section>

      <section className="first-box">
        <h2>5. Sécurité des données</h2>
        <p>
          Nous mettons en place des mesures de sécurité appropriées pour
          protéger vos données contre tout accès non autorisé, divulgation,
          modification ou destruction. Cependant, la sécurité des transmissions
          via Internet ne peut être garantie à 100%.
        </p>
      </section>

      <section className="first-box">
        <h2>6. Durée de conservation des données</h2>
        <p>
          Les données liées aux annonces, y compris les adresses email, sont
          conservées pendant 45 jours à compter de la publication. Après cette
          période, elles sont automatiquement supprimées. Le numéro de téléphone
          est conservé uniquement si l'utilisateur choisit de participer au
          programme de points. Une fois que 1000 points sont atteints, une
          récompense est attribuée et les points du numéro sont réinitialisés.
        </p>
      </section>

      <section className="first-box">
        <h2>7. Vos droits</h2>
        <p>
          Vous disposez de plusieurs droits concernant vos données personnelles,
          que vous pouvez exercer en nous contactant à{" "}
          <strong>dev.frenchlod@gmail.com</strong> :
        </p>
        <ul>
          <li>
            Droit d'accès : demander une copie de vos données personnelles.
          </li>
          <li>
            Droit de rectification : corriger des informations inexactes ou
            incomplètes.
          </li>
          <li>
            Droit à l'effacement : demander la suppression de vos données.
          </li>
          <li>
            Droit à la limitation : restreindre le traitement de vos données
            dans certains cas.
          </li>
          <li>
            Droit à la portabilité : recevoir vos données dans un format
            structuré.
          </li>
          <li>
            Droit d’opposition : refuser que vos données soient utilisées,
            notamment à des fins de marketing.
          </li>
        </ul>
      </section>

      <section className="first-box">
        <h2>8. Cookies</h2>
        <p>
          Nous utilisons des cookies pour améliorer l'expérience utilisateur et
          analyser le trafic du site à l'aide de Google Analytics. Les cookies
          nécessaires au bon fonctionnement du site ne peuvent être désactivés,
          mais vous avez la possibilité de refuser ceux utilisés à des fins
          d’analyse.
        </p>
      </section>

      <section className="first-box">
        <h2>9. Modifications de la Politique de Confidentialité</h2>
        <p>
          Nous nous réservons le droit de modifier cette politique de
          confidentialité à tout moment. Toute modification sera publiée sur
          cette page sans préavis, et il est recommandé aux utilisateurs de
          consulter régulièrement cette page.
        </p>
      </section>

      <section className="first-box">
        <h2>10. Contact</h2>
        <p>
          Si vous avez des questions concernant cette politique de
          confidentialité ou la gestion de vos données personnelles, vous pouvez
          nous contacter à l'adresse suivante :
          <br />
          <strong>Email :</strong> dev.frenchlod@gmail.com
        </p>
      </section>
    </StyledPolitique>
  );
};

export default Politique;
const StyledPolitique = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  h1 {
    text-align: center;
    font-size: 2.4em;
    letter-spacing: 0.4em;
  }
  .first-box {
    margin: 10px 0px;
    strong {
      font-size: 1.3em;
      padding: 5px;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
      margin: 0px 10px;
    }
    p {
      margin: 20px 0px;
      font-size: 1.1em;
      strong {
        color: ${COLORS.orange};
      }
    }
    h2 {
      margin: 15px 0px;
      font-size: 1.8em;
    }
    .info-important {
      margin: 20px auto;
      background: ${COLORS.blueLow};
      width: 50%;
      padding: 10px;
      color: ${COLORS.danger};
      text-align: center;
    }
  }
`;
