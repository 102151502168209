import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../COLORS";

const Recompense = ({
  setCompo,
}: {
  setCompo: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <StyledRecompense>
      <h1>Récompense</h1>
      <div className="first-box">
        <h2>Pour les particuliers</h2>
        <p className="info-important">
          **Premier arrivé = Premier servi** Les récompenses sont à gagner une
          seule fois. Elles ne peuvent être réattribuées, renouvelées ou
          accumulées.
        </p>
        <p className="info-important">
          Une fois gagnées, les récompenses sont supprimées.
        </p>
        <p>
          <strong>Emoh Up </strong> récompense{" "}
          <strong>uniquement les particuliers</strong> qui publient des annonces
          sur emohup.fr régulièrement.*
        </p>
        <p className="info-important">
          *Emoh Up se réserve le droit d'interrompre l'accumulation de points et
          des récompenses et de modifier/supprimer les conditions.
        </p>
        <strong>1 Point : </strong>
        <p>
          1 point est attribué au numéro utilisé par l'annonceur pour chaque
          annonce <strong>publiée.</strong> Et cela <strong>uniquement</strong>{" "}
          si l'annonceur accepte de gagner 1 point. Si l'annonce est refusée,
          aucun point n'est attribué au numéro.
        </p>
        <strong>200 Points : </strong>
        <p>
          Dès 200 points attribués à un numéro, Emoh Up s'engage à récompenser
          l'annonceur.
        </p>
        <p className="info-important">
          *Si une fausse annonce et/ou abus est détecté(e), le numéro peut
          perdre la totalité de ses points, sans donner suite (avis,
          explication, etc).
        </p>
        <p>
          Après la récompense, les points sont réinitialisés à 0 pour le numéro
          concerné. <strong>Aucun point</strong> ne peut être transféré à un
          autre numéro.
        </p>
        <p>
          En choisisant de gagner des points vous{" "}
          <strong>confirmez avoir lu et acceptez</strong> les présents
          conditions/information{" "}
        </p>
        <strong className="clic-cgu" onClick={() => setCompo("cgu")}>
          Cliquez pour voir les CGU
        </strong>
      </div>
    </StyledRecompense>
  );
};

export default Recompense;

const StyledRecompense = styled.div`
  /* background: red; */
  height: 100vh;
  h1 {
    font-size: 2.8em;
    letter-spacing: 0.4em;
  }
  .first-box {
    margin: 10px 0px;
    strong {
      font-size: 1.3em;
      padding: 5px;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
      margin: 0px 10px;
    }
    p {
      margin: 20px 0px;
      font-size: 1.1em;
      strong {
        color: ${COLORS.orange};
      }
    }
    h2 {
      margin: 15px 0px;
      font-size: 1.8em;
    }
    .info-important {
      margin: 20px auto;
      background: ${COLORS.blueLow};
      width: 50%;
      padding: 10px;
      color: ${COLORS.danger};
      text-align: center;
    }
    .clic-cgu {
      cursor: pointer;
      border-radius: 10px;
      color: ${COLORS.black};
      background: ${COLORS.purple};
    }
  }
  //width =< 429px
  @media screen and (max-width: 429px) {
    h1 {
      font-size: 2.2em;
      font-weight: normal;
    }
    .first-box {
      strong {
        font-size: 1em;
        font-weight: normal;
        padding: 5px;
        margin: 10px;
      }
      .info-important {
        width: 100%;
      }
      p {
        margin: 20px 0px;
        font-size: 0.8em;
      }
    }
  }
`;
