import React, { useRef, useState } from "react";
import styled from "styled-components";
import { BiSolidImageAdd } from "react-icons/bi";
import { COLORS } from "../../../../COLORS";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdDeleteSweep } from "react-icons/md";
import { Dynamic } from "../../../Context/ContextDynamic";
const FormImages = () => {
  const [infoImg, setInfoImg] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [imgSelected, setImgSelected] = useState<string[]>([]);
  const { setFilesUpload } = Dynamic();
  const handleIconClick = () => {
    if (inputRef.current) {
      inputRef.current.click(); // Déclenche le clic sur l'input caché
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = event.target.files;
    console.log("on joue");

    if (files && files.length > 3) {
      setInfoImg("Vous pouvez télécharger un maximum de 3 fichiers.");
      inputRef.current!.value = ""; // Réinitialiser l'input si plus de 3 fichiers
      const timeoutId = setTimeout(() => {
        setInfoImg("");
      }, 3000);

      // Nettoie le timeout si le composant est démonté ou l'effet est ré-exécuté
      return () => clearTimeout(timeoutId);
    } else if (files) {
      const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
      //   const maxSize = 233826;
      console.log("Fichiers sélectionnés : ", files);
      const fileArray = Array.from(files); // Convertir FileList en tableau
      console.log("filearray : ", fileArray);
      let array = [];

      //to one image
      if (fileArray.length === 1) {
        console.log("loooool");

        for (let i = 0; i < fileArray.length; i++) {
          console.log("la taille : ", fileArray[i].size);

          if (fileArray[i].size < maxSize) {
            const fileUrls = URL.createObjectURL(fileArray[i]);
            console.log(fileUrls);
            array.push(fileUrls);
            setImgSelected(array);
            //pour envoyé dans le back une image
            setFilesUpload([fileArray[i]]);
            return;
          } else {
            setInfoImg("Image trop grande");
            return;
          }
        }
      }

      //more image than 1
      for (let i = 0; i < fileArray.length; i++) {
        console.log("la taille : ", fileArray[i].size);

        if (fileArray[i].size < maxSize) {
          const fileUrls = URL.createObjectURL(fileArray[i]);
          console.log(fileUrls);
          array.push(fileUrls);
        }
      }
      if (fileArray.length !== array.length) {
        setInfoImg("Une image trop grande a été enlevé");
      }
      console.log(array);
      setFilesUpload(fileArray);
      setImgSelected(array);
    }
  };

  const settings = {
    dots: true,
    infinite: imgSelected.length > 1, // Si une seule image, pas de boucle infinie
    speed: 500,
    slidesToShow: imgSelected.length < 3 ? imgSelected.length : 3, // Si moins de 3 images, montrer seulement le nombre d'images disponibles
    slidesToScroll: 1,
    arrows: true,
  };

  const cancelImage = () => {
    setFilesUpload(null);
    setImgSelected([]);
  };
  return (
    <StyledFormImages>
      <span className="danger-img">{infoImg}</span>
      <div className="input-n-icon">
        <span className="leg-img">3 images max (optionnelles)</span>
        <input
          type="file"
          ref={inputRef}
          multiple
          accept=".png, .jpg, .jpeg"
          onChange={handleFileChange}
        />
        <BiSolidImageAdd onClick={handleIconClick} className="icon-add" />
      </div>
      {imgSelected && imgSelected.length > 0 && (
        <div className="preview-img">
          <MdDeleteSweep
            className="delete-icon"
            onClick={() => cancelImage()}
          />
          <span className="nb-img">Nombre image(s) : {imgSelected.length}</span>
          <Slider {...settings} className="slider">
            {imgSelected &&
              imgSelected.map((img, index) => (
                <img src={img} alt={img} key={index} />
              ))}
          </Slider>
        </div>
      )}
    </StyledFormImages>
  );
};

export default FormImages;

const StyledFormImages = styled.div`
  /* background: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  .danger-img {
    color: ${COLORS.danger};
    transition: color 0.3s ease;
    font-size: 0.7em;
  }
  .input-n-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: 5px auto;
    background: ${COLORS.white};
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
    .leg-img {
      font-size: 0.7em;
    }
    input {
      display: none;
    }
    .icon-add {
      cursor: pointer;
      font-size: 3.5em;
      color: ${COLORS.purple};
      transition: 0.3s ease, color 0.3s ease;
    }
    .icon-add:hover {
      color: ${COLORS.orange};
    }
  }
  .preview-img {
    width: 100%;
    display: flex;
    margin: 10px 0px;
    justify-content: center;
    flex-direction: column;
    .delete-icon {
      background: ${COLORS.purple};
      padding: 5px;
      margin: 10px;
      color: ${COLORS.danger};
      font-size: 2.3em;
      border-radius: 20px;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
      cursor: pointer;
    }
    .nb-img {
      font-size: 0.7em;
    }
  }
`;
