import React, { useEffect, useState } from "react";
import styled from "styled-components";
import EtapeOne from "./EtapeOne";
import EtapeTwo from "./EtapeTwo";
import { COLORS } from "../../../../COLORS";
import { GrNext } from "react-icons/gr";
import { IoIosArrowBack } from "react-icons/io";
import axios from "axios";
import { toast } from "react-toastify";
import { Dynamic } from "../../../Context/ContextDynamic";
import BtnLoader from "../../../Utils/BtnLoader";
import FormSimple from "../Simple/FormSimple";
type OptionType = {
  value: string;
  label: string;
};
const FormAnnonce = () => {
  const [textInfo, setTextInfo] = useState("");
  const [etapeForm, setEtapeForm] = useState(0);

  //catch value etape 1
  const [pseudo, setPseudo] = useState("");
  const [selectedCity, setSelectedCity] = useState<OptionType | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<OptionType | null>(
    null
  );
  const [gainPoint, setGainPoint] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [numPhone, setNumPhone] = useState<string | undefined>();
  const [hiden, setHiden] = useState("");
  const [ready, setReady] = useState(false);
  //catch value etape 2
  const [titre, setTitre] = useState("");
  const [description, setDescription] = useState("");
  const [accept, setAccept] = useState(false);
  const [price, setPrice] = useState("");

  //annonce simple
  const [simple, setSimple] = useState(false);

  //context
  const { filesUpload } = Dynamic();

  const avancedForm = () => {
    if (etapeForm === 0) {
      return (
        <EtapeOne
          pseudo={pseudo}
          setPseudo={setPseudo}
          setSelectedCity={setSelectedCity}
          selectedCity={selectedCity}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          setSelectedType={setSelectedType}
          selectedType={selectedType}
          numPhone={numPhone}
          setNumPhone={setNumPhone}
          gainPoint={gainPoint}
          setGainPoint={setGainPoint}
        />
      );
    } else if (etapeForm === 1) {
      return (
        <EtapeTwo
          titre={titre}
          setTitre={setTitre}
          description={description}
          setDescription={setDescription}
          price={price}
          setPrice={setPrice}
        />
      );
    }
  };

  const changeEtape = () => {
    if (
      pseudo &&
      selectedCity &&
      selectedCategory &&
      selectedType &&
      numPhone
    ) {
      if (etapeForm === 0) {
        if (pseudo.length > 10) {
          return setTextInfo("Pseudo trop long, max 15 caractères");
        }
        setTextInfo("");
        if (numPhone.length !== 12) {
          return setTextInfo("Format attendu : +594 694 xxx xxx");
        } else {
          setTextInfo("");
          setEtapeForm(1);
        }
      } else {
        setEtapeForm(0);
      }
    } else {
      setTextInfo("Champs obligatoires");
    }
  };

  const subForm = async (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (
      !pseudo ||
      !selectedCity ||
      !selectedCategory ||
      !selectedType ||
      !numPhone ||
      !titre ||
      !description ||
      !price
    ) {
      setTextInfo("Les champs sont obligatoires");
      setReady(false);
      const timeText = setTimeout(() => {
        setTextInfo("");
      }, 3000);
      return () => clearTimeout(timeText);
    }

    if (pseudo.length > 10) {
      return setTextInfo("Pseudo trop long, max 15 caractères");
    }
    if (description.length > 160) {
      return setTextInfo("Description trop long, max 160 caractères");
    }
    if (titre.length > 25) {
      return setTextInfo("Titre trop long, max 25 caractères");
    }

    const data = new FormData();
    data.append("pseudo", pseudo);
    data.append("ville", selectedCity.value);
    data.append("categorie", selectedCategory.value);
    data.append("type", selectedType);
    data.append("numero", numPhone);
    data.append("titre", titre);
    data.append("description", description);
    data.append("price", price);
    data.append("point", gainPoint ? "true" : "false");
    data.append("hidden", hiden);

    if (filesUpload && filesUpload.length > 0) {
      // Boucle pour ajouter chaque fichier à FormData
      filesUpload.forEach((file, index) => {
        data.append("files", file); // "files" est le nom attendu dans votre back-end
      });
    }
    setReady(true);
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}annonces/create`,
        withCredentials: true,
        data,
      });
      if (res.data.message) {
        setReady(false);
        initAllValue();
        return toast.success(res.data.message);
      }
      setReady(false);
    } catch (error) {
      setReady(false);
      const typedError = error as any;
      console.log(typedError.response.data.message);
      setTextInfo(typedError.response.data.message);
      const timeText = setTimeout(() => {
        setTextInfo("");
      }, 3000);
      return () => clearTimeout(timeText);
    }
  };

  const initAllValue = () => {
    setAccept(false);
    setEtapeForm(0);
    setPseudo("");
    setSelectedCity(null);
    setSelectedCategory(null);
    setSelectedType("");
    setNumPhone("");
    setTitre("");
    setDescription("");
    setPrice("");
    setGainPoint(false);
  };

  const wahtBtnToSend = () => {
    if (ready) {
      return <BtnLoader />;
    } else {
      return <input type="submit" className="btn-sub" value="Envoyer" />;
    }
  };

  useEffect(() => {
    if (pseudo.length > 10) {
      setTextInfo("Pseudo trop long, max 15 caractères");
    } else {
      setTextInfo("");
    }
  }, [pseudo]);
  useEffect(() => {
    if (titre.length > 25) {
      setTextInfo("Titre trop long, max 25 caractères");
    } else {
      setTextInfo("");
    }
  }, [titre]);

  useEffect(() => {
    if (description.length > 160) {
      setTextInfo("Description trop long, max 160 caractères");
    } else {
      setTextInfo("");
    }
  }, [description]);
  return (
    <StyledFormAnnonce onSubmit={(e) => subForm(e)}>
      <div className="classic-or-simple">
        <button
          className={!simple ? "actif" : ""}
          onClick={() => setSimple(false)}
        >
          Annonce
        </button>
        <button
          className={simple ? "actif" : ""}
          onClick={() => setSimple(true)}
        >
          Simple
        </button>
      </div>
      {simple ? (
        <FormSimple />
      ) : (
        <>
          <span className={textInfo ? "dan-ger" : ""}>
            Annonce : {textInfo}
          </span>
          <form>
            {avancedForm()}
            {etapeForm === 1 && (
              <div className="cgu">
                <input
                  type="checkbox"
                  onChange={() => setAccept((prev) => !prev)}
                />
                <span>J'accepte les CGU, politique de confidentialité</span>
              </div>
            )}
            {etapeForm === 1 && accept && wahtBtnToSend()}
            <input type="hidden" onChange={(e) => setHiden(e.target.value)} />
            <span className="indication">*Obligatoire</span>
            {gainPoint && (
              <span className="indication">
                **1 point après validation de l'annonce
              </span>
            )}
          </form>
          <div className="box-btn-avanced">
            <button className="btn-avanced" onClick={() => changeEtape()}>
              {etapeForm === 0 ? "Continuer" : "Retour"}
              {etapeForm === 0 ? <GrNext /> : <IoIosArrowBack />}
            </button>
          </div>
        </>
      )}
    </StyledFormAnnonce>
  );
};

export default FormAnnonce;
const StyledFormAnnonce = styled.div`
  background: ${COLORS.blueLow};
  width: 50%;
  border-radius: 10px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  .classic-or-simple {
    margin-bottom: 10px;
    button {
      padding: 5px;
      letter-spacing: 0.4em;
      outline: none;
      margin-right: 10px;
      border-radius: 5px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
      border: solid 2px ${COLORS.green};
      color: ${COLORS.greenDark};
      transition: background-color 0.3s ease;
      cursor: pointer;
    }
    .actif {
      background: ${COLORS.green};
      box-shadow: 0px 4px 15px #2df17e4c;
      color: ${COLORS.white};
    }
  }
  span {
    font-size: 1.3em;
    margin-bottom: 5px;
  }
  .dan-ger {
    transition: color 0.3s ease, color 0.3s ease;
    color: ${COLORS.danger};
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    .cgu {
      margin: 10px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      span {
        display: block;
        margin: 5px;
        align-items: center;
        font-size: 0.7em;
      }
    }
    .btn-sub {
      width: 30%;
      margin-top: 20px;
      background: ${COLORS.green};
      padding: 10px;
      border-radius: 5px;
      outline: none;
      border: none;
      cursor: pointer;
      font-size: 1.2em;
      //font-weight: 700;
      color: ${COLORS.white};
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
      transition: 0.3s ease, color 0.3s ease;
    }
    .btn-sub:hover {
      background: ${COLORS.purple};
    }
    .indication {
      width: 100%;
      /* margin-top: 10px; */
      font-size: 0.7em;
    }
  }
  .box-btn-avanced {
    width: 20%;
    margin: 5px auto;
    display: flex;
    justify-content: center;
  }
  .box-btn-avanced > .btn-avanced {
    width: 100%;
    margin-top: auto;
    background: ${COLORS.green};
    padding: 10px;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
    //font-weight: 700;
    color: ${COLORS.white};
    display: flex;
    align-items: center;
    justify-content: space-around;
    transition: 0.3s ease, color 0.3s ease;
  }
  .btn-avanced:hover {
    background: ${COLORS.purple};
  }

  //width =< 429px
  @media screen and (max-width: 429px) {
    width: 95%;
    form {
      .btn-sub {
        width: 50%;
      }
    }
    .box-btn-avanced {
      width: 50%;
    }
  }
`;
