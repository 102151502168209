import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../COLORS";

const Mentions = () => {
  return (
    <StyledMentions>
      <section className="first-box">
        <h1>Mentions Légales - Emohup.fr</h1>

        <h2>1. Identité de l'Éditeur du Site</h2>
        <p>
          <strong>Éditeur :</strong> Le site www.emohup.fr est édité par
          l'équipe de développement EmohUp.
          <br />
          <strong>Adresse email :</strong> dev.frenchlod@gmail.com
        </p>

        <h2>2. Directeur de la Publication</h2>
        <p>
          {/* <strong>Directeur de la publication :</strong> */}
          <br />
          <strong>Adresse email :</strong> dev.frenchlod@gmail.com
        </p>

        <h2>3. Hébergeur du Site</h2>
        <p>
          <strong>Hébergeur :</strong> OVH
          <br />
          <strong>Adresse :</strong> 2 Rue Kellermann, 59100 Roubaix, France
          <br />
          <strong>Numéro de téléphone :</strong> +33 9 72 10 10 07
          <br />
          <strong>Adresse email de contact :</strong> support@ovh.com
        </p>

        <h2>4. Propriété Intellectuelle</h2>
        <p>
          Tous les contenus présents sur le site www.emohup.fr (textes, images,
          logos, vidéos, etc.) sont protégés par les lois sur la propriété
          intellectuelle et le droit d'auteur. Toute reproduction, distribution,
          modification ou utilisation de ces contenus sans autorisation
          préalable est strictement interdite.
        </p>

        <h2>5. Conditions Générales d'Utilisation (CGU)</h2>
        <p>
          Les Conditions Générales d'Utilisation (CGU) sont disponibles sur le
          site et doivent être acceptées par tout utilisateur avant
          l'utilisation des services du site.
        </p>

        <h2>6. Politique de Confidentialité</h2>
        <p>
          La Politique de Confidentialité d’Emohup.fr est disponible sur le
          site. Elle décrit les modalités de collecte, d’utilisation et de
          protection des données personnelles des utilisateurs conformément aux
          dispositions légales en vigueur.
        </p>

        <h2>7. Cookies</h2>
        <p>
          Le site www.emohup.fr utilise des cookies pour améliorer l'expérience
          utilisateur et pour des analyses de trafic. Un bandeau informatif sur
          l'utilisation des cookies est affiché lors de la première visite de
          l'utilisateur, lui permettant de les accepter ou de les refuser.
        </p>

        <h2>8. Liens Externes</h2>
        <p>
          Le site Emohup.fr peut contenir des liens vers des sites externes.
          L’éditeur du site décline toute responsabilité quant au contenu de ces
          sites externes ou à leur utilisation.
        </p>

        <h2>9. Modifications des Mentions Légales</h2>
        <p>
          Les présentes mentions légales peuvent être modifiées à tout moment
          sans préavis. Les utilisateurs sont invités à consulter régulièrement
          cette page pour prendre connaissance des éventuelles modifications.
        </p>

        <h2>10. Droit Applicable</h2>
        <p>
          Les présentes mentions légales sont régies par le droit français. En
          cas de litige, seuls les tribunaux français sont compétents.
        </p>

        <h2>11. Contact</h2>
        <p>
          Pour toute question, réclamation ou demande d'information concernant
          les mentions légales, vous pouvez nous contacter à l'adresse suivante
          :
          <br />
          <strong>Adresse email :</strong> dev.frenchlod@gmail.com
        </p>
      </section>
    </StyledMentions>
  );
};

export default Mentions;

const StyledMentions = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  h1 {
    font-size: 2.5em;
    letter-spacing: 0.4em;
    text-align: center;
  }
  .first-box {
    margin: 10px 0px;
    p {
      margin: 20px 0px;
      font-size: 1.1em;
      strong {
        color: ${COLORS.orange};
      }
    }
    h2 {
      margin: 15px 0px;
      font-size: 1.8em;
    }
    .info-important {
      margin: 20px auto;
      background: ${COLORS.blueLow};
      width: 50%;
      padding: 10px;
      color: ${COLORS.danger};
      text-align: center;
    }
  }
`;
