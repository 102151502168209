import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../COLORS";
import { CgProfile } from "react-icons/cg";
type TypeProps = {
  pseudo: string;
  titre: string;
  description: string;
};
const ListsSimples = ({ pseudo, titre, description }: TypeProps) => {
  return (
    <StyledListsSimples>
      <h1>{titre}</h1>
      <strong>
        {" "}
        <CgProfile className="icon" /> {pseudo}
      </strong>
      <p>{description}</p>
    </StyledListsSimples>
  );
};

export default ListsSimples;
const StyledListsSimples = styled.div`
  background: ${COLORS.purple};
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 4px 15px ${COLORS.purple};
  margin: 20px;
  width: 40%;
  h1 {
    font-size: 1.3em;
  }
  strong {
    .icon {
      margin-right: 5px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    padding: 5px 0px;
    background: ${COLORS.orange};
    margin: 5px 0px;
  }
  @media screen and (max-width: 429px) {
    width: 100%;
  }
`;
