import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../COLORS";
import Annonce from "./Annonce/Annonce";
import axios from "axios";
import PopOneAnnonce from "./PopOneAnnonce";
import FilterAnnonce from "./FilterAnnonces/FilterAnnonce";
import SimpleAnnonce from "./SimpleAnnonces/SimpleAnnonce";
type TypeAnnonce = {
  categorie: string;
  createdAt: string;
  description: string;
  images?: [string];
  numero: string;
  price: string;
  pseudo: string;
  status: number;
  titre: string;
  type: string;
  updatedAt: string;
  ville: string;
  __v: number;
  _id: string;
};
const Annonces = () => {
  const [annonces, setAnnonces] = useState<TypeAnnonce[]>([]);
  const [oneAnnonce, setOneAnnonce] = useState("");
  const [getCat, setGetCat] = useState("");
  const [annonceSimple, setAnnonceSimple] = useState(false);

  const getAllAnnonces = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}annonces/all`,
        withCredentials: true,
      });
      if (res.data) {
        setAnnonces(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //with categorie
  const getByCat = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}annonces/filter/${getCat}`,
        withCredentials: true,
      });
      console.log(res);
      if (res.data) {
        setAnnonces(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (getCat) {
      getByCat();
    } else {
      getAllAnnonces();
    }
  }, [getCat]);
  return (
    <StyledAnnonces>
      <div className="choose-annonce">
        <button
          className={!annonceSimple ? "actif" : ""}
          onClick={() => setAnnonceSimple(false)}
        >
          Annonces
        </button>
        <button
          className={annonceSimple ? "simple actif" : "simple"}
          onClick={() => setAnnonceSimple(true)}
        >
          Simples
        </button>
      </div>
      {!annonceSimple ? (
        <>
          <FilterAnnonce setGetCat={setGetCat} />
          {oneAnnonce && (
            <PopOneAnnonce
              oneAnnonce={oneAnnonce}
              setOneAnnonce={setOneAnnonce}
            />
          )}
          {annonces && annonces.length > 0 ? (
            annonces
              .sort(
                (a, b) =>
                  new Date(b.createdAt).getTime() -
                  new Date(a.createdAt).getTime()
              )
              .map((annonce) => (
                <Annonce
                  key={annonce._id}
                  id={annonce._id}
                  categorie={annonce.categorie}
                  description={annonce.description}
                  image={annonce.images}
                  numero={annonce.numero}
                  price={annonce.price}
                  pseudo={annonce.pseudo}
                  titre={annonce.titre}
                  type={annonce.type}
                  ville={annonce.ville}
                  setOneAnnonce={setOneAnnonce}
                />
              ))
          ) : (
            <strong>
              Soyez les premiers a gagner des points en postant une annonce
            </strong>
          )}
        </>
      ) : (
        <SimpleAnnonce />
      )}
    </StyledAnnonces>
  );
};

export default Annonces;

const StyledAnnonces = styled.div`
  background: ${COLORS.white};
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  //width =< 429px
  .choose-annonce {
    width: 100%;
    button {
      padding: 5px;
      letter-spacing: 0.4em;
      outline: none;
      border-radius: 5px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
      border: solid 2px ${COLORS.green};
      color: ${COLORS.greenDark};
      transition: background-color 0.3s ease;
      cursor: pointer;
    }
    .simple {
      margin-left: 10px;
    }
    .actif {
      background: ${COLORS.green};
      box-shadow: 0px 4px 15px #2df17e4c;
      color: ${COLORS.white};
    }
  }
  @media screen and (max-width: 429px) {
    margin-top: 50px;
    padding: 0px;
    .choose-annonce {
      margin-top: 50px;
      padding: 5px;
    }
  }
`;
