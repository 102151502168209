import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../../../COLORS";
import BtnLoader from "../../../Utils/BtnLoader";
import axios from "axios";
import { toast } from "react-toastify";

const FormSimple = () => {
  const [info, setInfo] = useState("");
  const [pseudo, setPseudo] = useState("");
  const [titre, setTitre] = useState("");
  const [description, setDescription] = useState("");
  const [hidden, setHidden] = useState("");

  //bntn loader
  const [sending, setSending] = useState(false);

  const subSimple = async (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!pseudo || !titre || !description) {
      setInfo("Tous les champs sont obligatoires");
      const timeText = setTimeout(() => {
        setInfo("");
      }, 3000);
      return () => clearTimeout(timeText);
    }
    checkLength();
    setSending(true);
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}simple-annonces/create`,
        withCredentials: true,
        data: {
          pseudo,
          titre,
          description,
          hidden,
        },
      });
      setSending(false);
      if (res.data.message) {
        setPseudo("");
        setTitre("");
        setDescription("");
        return toast.success(res.data.message);
      }
    } catch (error) {
      console.log(error);
      setSending(false);
      return toast.error("Une erreur est survenue...");
    }
  };

  const checkLength = () => {
    if (pseudo.length > 12) {
      setInfo("Pseudo trop long, max 12 caractères");
    } else if (titre.length > 20) {
      setInfo("Titre trop long, max 20 caractères");
    } else if (description.length > 170) {
      setInfo("Description trop longue, max 170 caractères");
    } else {
      setInfo("");
    }
  };
  useEffect(() => {
    if (pseudo || titre || description) {
      checkLength();
    }
  }, [pseudo, titre, description]);
  return (
    <StyledFormSimple onSubmit={(e) => subSimple(e)}>
      <span className={info ? "color" : ""}>Annonce Simple : {info}</span>
      <input
        type="text"
        placeholder="Pseudo*"
        value={pseudo ? pseudo : ""}
        onChange={(e) => setPseudo(e.target.value)}
      />
      <input
        type="text"
        placeholder="Titre*"
        value={titre ? titre : ""}
        onChange={(e) => setTitre(e.target.value)}
      />
      <textarea
        placeholder="Description*"
        value={description ? description : ""}
        onChange={(e) => setDescription(e.target.value)}
      ></textarea>
      <input type="hidden" onChange={(e) => setHidden(e.target.value)} />
      {!sending ? (
        <input
          type="submit"
          value="Envoyer"
          className="btn-sub"
          onClick={(e) => subSimple(e)}
        />
      ) : (
        <BtnLoader />
      )}
    </StyledFormSimple>
  );
};

export default FormSimple;
const StyledFormSimple = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  span {
    width: 100%;
    margin-bottom: 10px;
  }
  .color {
    color: ${COLORS.danger};
  }
  input,
  textarea {
    width: 50%;
    padding: 5px;
    border-radius: 5px;
    outline: none;
    border: none;
    border-bottom: solid 2px ${COLORS.green};
    font-size: 1.2em;
    margin-bottom: 15px;
  }
  .btn-sub {
    width: 30%;
    background: ${COLORS.green};
    color: ${COLORS.white};
    cursor: pointer;
  }
  textarea {
    height: 15vh;
    resize: none;
  }
  //width =< 429px
  @media screen and (max-width: 429px) {
    span {
      font-size: 0.9em;
    }
    input,
    textarea {
      width: 70%;
    }
  }
`;
