import React from "react";
import styled from "styled-components";

const Evenements = () => {
  return (
    <StyledEvenements>
      En cours de développement - ici seront publié vos événements(soirée,
      sortie ect...)
    </StyledEvenements>
  );
};

export default Evenements;
const StyledEvenements = styled.div`
  padding: 10px;
  @media screen and (max-width: 429px) {
    margin-top: 50px;
  }
`;
