import React from "react";
import styled from "styled-components";
import { GoHomeFill } from "react-icons/go";
import { TbLayoutListFilled } from "react-icons/tb";
import { BiSolidCalendarAlt } from "react-icons/bi";
import { MdCloudUpload } from "react-icons/md";
import { BsPatchQuestionFill } from "react-icons/bs";
import { COLORS } from "../../COLORS";
import { useLocation, useNavigate } from "react-router-dom";

const NavigationMobile = () => {
  const nav = useNavigate();
  const location = useLocation();
  return (
    <StyledNavigationMobile>
      <ol>
        <li onClick={() => nav("/")}>
          <GoHomeFill
            className={`icon-nav ${location.pathname === "/" && "activ"}`}
          />
          <span>Accueil</span>
        </li>
        <li onClick={() => nav("/annonces")}>
          <TbLayoutListFilled
            className={`icon-nav ${
              location.pathname === "/annonces" && "activ"
            }`}
          />
          <span>Annonces</span>
        </li>
        <li onClick={() => nav("/evenements")}>
          <BiSolidCalendarAlt
            className={`icon-nav ${
              location.pathname === "/evenements" && "activ"
            }`}
          />
          <span>Evénement</span>
        </li>
        <li onClick={() => nav("/points")}>
          <BsPatchQuestionFill
            className={`icon-nav ${location.pathname === "/points" && "activ"}`}
          />
          <span>Points</span>
        </li>
        <li onClick={() => nav("/post")}>
          <MdCloudUpload
            className={`icon-nav ${location.pathname === "/post" && "activ"}`}
          />
          <span>Publier</span>
        </li>
      </ol>
    </StyledNavigationMobile>
  );
};

export default NavigationMobile;
const StyledNavigationMobile = styled.nav`
  display: none;
  @media screen and (max-width: 429px) {
    display: block;
    position: fixed;
    bottom: 0px;
    width: 100%;
    background: ${COLORS.purple};
    padding: 10px 10px 30px 10px;
    ol {
      display: flex;
      justify-content: space-around;
      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon-nav {
          font-size: 2em;
          color: ${COLORS.white};
        }
        .activ {
          color: ${COLORS.green};
        }
        span {
          font-weight: 100;
          font-size: 0.8em;
        }
      }
    }
  }
`;
