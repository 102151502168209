import { createContext, useContext, useState } from "react";
import {
  TypeContextDynamic,
  TypeContextDynamicProviderProps,
} from "./TypeContext";

const ContextDynamic = createContext<TypeContextDynamic | undefined>(undefined);

export const ContextDynamicProvider = ({
  children,
}: TypeContextDynamicProviderProps) => {
  const [filesUpload, setFilesUpload] = useState<File[] | null>(null);

  return (
    <ContextDynamic.Provider value={{ filesUpload, setFilesUpload }}>
      {children}
    </ContextDynamic.Provider>
  );
};

export const Dynamic = () => {
  const context = useContext(ContextDynamic);
  if (!context) {
    throw new Error(
      "useContextDynamic must be used within a ContextDynamicProvider"
    );
  }

  return context;
};
