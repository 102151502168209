export const COLORS = {
    white: "#f4e7fb",
    yellowLow: "#f3dcdc",
    orange: "#f5bcba",
    purpleLow: "#e3aadd",
    purple: "#c8a8e9",
    blueLow: "#c3c7f3",
    black: "#636363",
    grey: "#e8e8e8",
    danger: "#fb5656",
    gold: "#fcff73",
    green: "#2fac66",
    greenDark:"#2c754c",
}